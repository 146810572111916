#page {
  margin: 0 auto;
  background: $dark;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.show {
    .pageHeader {
      opacity: 1;
      transform: translate(0, 0);
      transition: all 1s ease-in-out;
      transition-delay: 0.2s;
    }
    .pageContainer {
      opacity: 1;
      transform: scale(1);
      transition: all 1s ease-in-out;
    }
  }
  .pageContainer {
    max-width: 100%;
    opacity: 0;
    transform: scale(0.99);
    transition: all 1s ease-in-out;
  }
  .pageHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 60px;
    opacity: 0;
    transform: translate(0, 0);
    transition: all 1s ease-in-out;
    backdrop-filter: blur(15px);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: $dark;
      opacity: 0.8;
    }
    nav {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      box-sizing: border-box;
    }
    #logo {
      width: 80px;
      fill: white;
      padding: 10px;
    }
    .btn {
      border-radius: 0;
      width: auto;
      margin: 0;
      padding: 0 20px 0 0;
      background: none;
      color: white;
      font-size: 0.8rem;
    }
  }
  .banner {
    background: #111;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image,
    .copy {
      flex: 1 1 50%;
    }
    .image {
      overflow: hidden;
      min-height: 50vh;
      background-size: cover;
      background-position: center;
      border-radius: 0;
    }
    .copy {
      padding: 40px;
      box-sizing: border-box;
      background: #111;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      border-radius: 0;
      .bg {
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 20rem;
        line-height: 17rem;
        transform: translate(-30px,-50%);
        color: black;
        z-index: 1;
        span {
          position: relative;
          left: 10px;
        }
      }
      h1,
      .desc {
        position: relative;
        z-index: 2;
      }
      h1 {
        align-self: flex-start;
        margin-top: 0;
      }
      p {
        color: darken(white, 30%);
        padding: 0;
      }
    }
    &.full {
      display: block;
      padding: 0;
      .image {
        border-radius: 0;
        .logos {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          img {
            width: 50%;
            height: auto;
          }
        }
      }
      .copy {
        display: flex;
        flex-direction: column;
        align-items: center;
        h1 {
          font-size: 2.4rem;
          margin: 0 0 20px;
        }
        .btn {
          flex: auto;
          margin: 0;
        }
        p {
          margin: 0 0 40px;
        }
      }
    }
  }
  .full {
    position: relative;
    background: $dark;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    // &:after {
    //   content: '';
    //   width: 100%;
    //   height: 30px;
    //   filter: blur(20px);
    //   position: absolute;
    //   border-radius: 50%;
    //   background: rgba(0,0,0,0.5);
    //   pointer-events: none;
    // }
    &.top {
      &:after {
        top: -50px;
      }
    }
    &.bottom {
      &:after {
        bottom: -50px;
      }
    }
    p {
      margin: 0 0 40px;
      font-size: 1.4rem;
      text-align: left;
      font-weight: 300;
      color: darken(white, 30%);
      &:last-child {
        margin: 0;
      }
    }
    .btnWrap {
      margin-top: 40px;
    }
  }
  .blocks {
    background: lighten($dark, 60%);
    color: #111111;
    position: relative;
    z-index: 10;
    article {
      box-sizing: border-box;
      padding: 40px;
      h3 {
        font-size: 1.4rem;
        line-height: 2.4rem;
      }
      p {
        font-size: 1.2rem;
        margin-bottom: 0;
        color: #666;
      }
    }
  }
}

.cta {
  width: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #111;
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(17,17,17,0.6);
  }
  .copy {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    #logo {
      position: relative;
      top: 0;
      left: 0;
      width: 200px;
    }
    p {
      padding: 40px 0;
      font-weight: bold;
    }
  }
}

@media (min-width: 768px) {
  #page {
    .pageHeader {
      padding: 0 60px;
      box-sizing: border-box;
    }
    .banner {
      flex-direction: row;
      min-height: 100vh;
      .copy {
        padding: 100px;
        border-radius: 0 0 20px 20px;
        h1 {
          font-size: 3rem;
          line-height: 3.6rem;
          line-break: break-word;
        }
        p {
          font-size: 1.2rem;
        }
        .btn {
          margin-top: 40px;
        }
      }
      &.full {
        padding: 0 40px;
        .copy {
          h1 {
            flex: 0 0 50%;
            font-size: 5rem;
            line-height: normal;
            letter-spacing: 6px;
            position: relative;
            font-weight: lighter;
            z-index: 2;
            span {
              position: relative;
              left: 3px;
            }
          }
          .desc {
            position: relative;
            z-index: 2;
            align-self: flex-start;
          }
        }
        .image {
          border-radius: 20px 20px 0 0;
          .logos {
            img {
              width: 25%;
            }
          }
        }
      }
    }
    .full {
      padding: 100px;
      p {
        font-size: 1.6rem;
      }
      .btnWrap {
        margin-top: 40px;
        align-self: flex-start;
      }
    }
    .blocks {
      padding: 60px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      article {
        padding: 40px 100px;
        h3 {
          font-size: 1.6rem;
        }
      }
    }
    .cta {
      padding: 100px;

      p {
        max-width: 800px;
        font-size: 2rem;
        margin: 40px 0 60px;
      }
    }
  }
}

@media (min-width: 991px) {
  #page {
    .blocks {
      article {
        flex: 0 0 50%;
      }
    }
  }
}

@media (min-width: 1200px) {
  #page {
    .full {
      flex-direction: row-reverse;
      p {
        padding-left: 100px;
        max-width: 800px;
      }
      .btnWrap {
        flex: 0 0 300px;
        margin-top: 0;
        align-self: center;
      }
    }
    .banner {
      &.full {
        .copy {
          flex-direction: row;
          h1 {
            margin: 0;
          }
          .desc {
            padding-left: 40px;
          }
        }
      }
    }
  }
}