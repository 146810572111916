@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Poppins:wght@100;300;400&display=swap");
@keyframes bounce {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(5px);
  }
}
@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body, html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background: #1e1f1e;
  scroll-behavior: smooth;
}

.hideMobile {
  display: none !important;
}

.showMobile {
  display: initial !important;
}

@property --angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}
@keyframes rotate {
  to {
    --angle: 360deg;
  }
}
.btnWrap {
  display: block;
  border-radius: 12px;
  box-sizing: border-box;
  border: 2px solid transparent;
  width: 100%;
  cursor: pointer;
  --bg: grey;
  --gradient: conic-gradient(
    from var(--angle),
    #70D6FF, #FF70A6, #FF9770, #FFD670, #E9FF70, #70D6FF
  );
  background-image: var(--gradient);
  background: linear-gradient(var(--bg), var(--bg)) padding-box, var(--gradient) border-box;
  animation: rotate 2s infinite linear running;
}
.btnWrap:is(:hover, :focus-within) {
  animation-play-state: paused;
}

.btn {
  background: #0d0d0d;
  text-align: center;
  width: 100%;
  line-height: 60px;
  margin: 0 auto;
  color: white;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 10px;
  border: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  text-shadow: none;
  font-size: 1.2rem;
}
.btn:hover {
  cursor: pointer;
  background: #1a1a1a;
  letter-spacing: 2.1px;
  transition: all 0.2s ease-in-out;
}
.btn:active {
  background: #141414;
  outline: none;
  box-shadow: 0 0 5px 5px black inset;
  transition: none;
}
.btn:focus {
  outline: none;
}

p {
  font-size: 1.4rem;
  font-weight: 300;
}

h3,
.keyTitle {
  margin: 0;
  font-size: 2rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
}
h3 .keyWrap,
.keyTitle .keyWrap {
  width: 85px;
  height: 80px;
  background: linear-gradient(to left top, #9a9a9a, silver);
  border-radius: 6px;
  padding: 2px;
}
h3 .key,
.keyTitle .key {
  display: none;
  width: 85px;
  height: 80px;
  font-size: 2rem;
  border-radius: 6px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background: lightgrey;
  font-weight: bold;
}
h3 .word,
.keyTitle .word {
  position: relative;
  top: 40px;
  left: 0;
  text-indent: 90px;
  display: block;
  flex: 0 0 100%;
  transform: translate(-88px, 0);
  padding-bottom: 20px;
}
h3 .word span,
.keyTitle .word span {
  text-indent: 0;
}
h3.dark span,
.keyTitle.dark span {
  background: #1e1f1e;
  border-color: white;
  color: white;
}

#logo {
  position: relative;
  z-index: 99;
  width: 150px;
  fill: white;
  box-sizing: border-box;
}
#logo #fill {
  fill: #1e1f1e;
}

#video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.container {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}
.container.out {
  transform: scale(0.98);
  transition: all 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .showMobile {
    display: none !important;
  }
  .hideMobile {
    display: inline-block !important;
  }
  .scroll {
    bottom: 80px;
  }
  .btnWrap,
  .btn {
    max-width: 300px;
  }
  #home #intro blockquote {
    font-size: 1.6rem;
  }
}
#home {
  position: relative;
  background: #1e1f1e;
}
#home #websites {
  background: none;
}
#home .clientList {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
}
#home .clientList a {
  display: block;
  max-width: 80px;
}
#home .clientList a:hover svg path {
  fill: white;
  transition: all 0.3s ease-in-out;
}
#home .clientList svg {
  width: 100%;
}
#home .clientList svg path {
  fill: #666666;
  transition: all 0.3s ease-in-out;
}
#home .textSection {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  padding: 20px;
  box-sizing: border-box;
  color: white;
}
#home .textSection .copy {
  max-width: 100%;
  box-sizing: border-box;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#home .textSection .copy #logo {
  width: 110px;
  margin-bottom: 40px;
}
#home .textSection .copy h1 {
  font-size: 3rem;
  margin: 0;
  align-self: flex-start;
  color: white;
}
#home .textSection .copy h2 {
  font-size: 2.4rem;
  margin: 0;
}
#home .textSection .copy p {
  color: #666666;
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 0;
  padding: 0 0 40px;
  font-weight: 300;
}
#home .textSection .copy p a {
  text-decoration: none;
  font-family: "helvetica", sans-serif;
  color: #b3b3b3;
  transition: all 0.3s ease-in-out;
}
#home .textSection .copy p a:hover {
  cursor: pointer;
  color: white;
  transition: all 0.3s ease-in-out;
}
#home .textSection .copy .btnGroup {
  display: flex;
  align-items: center;
  gap: 25px;
  margin: 0 0 40px;
}
#home .textSection .copy .btnGroup a {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #666666;
  text-decoration: none;
}
#home .textSection .copy .btnGroup a span {
  font-size: 0.5rem;
  letter-spacing: 1px;
  display: block;
  text-align: center;
  text-transform: uppercase;
  color: white;
  transition: all 0.2s ease-in-out;
}
#home .textSection .copy .btnGroup a svg {
  width: 100%;
  height: 25px;
  transform: translateY(0);
  transition: all 0.2s ease-in-out;
}
#home .textSection .copy .btnGroup a svg path,
#home .textSection .copy .btnGroup a svg circle {
  fill: white;
}
#home .textSection .copy .btnGroup a:hover {
  cursor: pointer;
}
#home .textSection .copy .btnGroup a:hover svg {
  transform: translateY(-5px);
  transition: all 0.2s ease-in-out;
}
#home .textSection .copy .btn {
  align-self: flex-start;
}

@media (min-width: 768px) {
  #home .textSection {
    padding: 80px 40px;
  }
  #home .textSection .btnGroup {
    gap: 40px;
  }
  #home .textSection .copy h1,
  #home .textSection .copy h2 {
    margin: 0;
    font-size: 5rem;
    white-space: nowrap;
  }
  #home .textSection .copy h2 {
    font-size: 3.6rem;
  }
  #home .textSection .copy p {
    font-size: 2rem;
    line-height: normal;
  }
}
@media (min-width: 991px) {
  #home .textSection {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  #home .textSection .copy {
    margin: 0 100px;
  }
  #home .textSection .copy p {
    font-size: 2.4rem;
  }
}
@media (min-width: 1200px) {
  #home .textSection .copy {
    max-width: 1080px;
    margin: 0 auto;
  }
}
#page {
  margin: 0 auto;
  background: #1e1f1e;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#page.show .pageHeader {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 1s ease-in-out;
  transition-delay: 0.2s;
}
#page.show .pageContainer {
  opacity: 1;
  transform: scale(1);
  transition: all 1s ease-in-out;
}
#page .pageContainer {
  max-width: 100%;
  opacity: 0;
  transform: scale(0.99);
  transition: all 1s ease-in-out;
}
#page .pageHeader {
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 60px;
  opacity: 0;
  transform: translate(0, 0);
  transition: all 1s ease-in-out;
  backdrop-filter: blur(15px);
}
#page .pageHeader:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #1e1f1e;
  opacity: 0.8;
}
#page .pageHeader nav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  box-sizing: border-box;
}
#page .pageHeader #logo {
  width: 80px;
  fill: white;
  padding: 10px;
}
#page .pageHeader .btn {
  border-radius: 0;
  width: auto;
  margin: 0;
  padding: 0 20px 0 0;
  background: none;
  color: white;
  font-size: 0.8rem;
}
#page .banner {
  background: #111;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#page .banner .image,
#page .banner .copy {
  flex: 1 1 50%;
}
#page .banner .image {
  overflow: hidden;
  min-height: 50vh;
  background-size: cover;
  background-position: center;
  border-radius: 0;
}
#page .banner .copy {
  padding: 40px;
  box-sizing: border-box;
  background: #111;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 0;
}
#page .banner .copy .bg {
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 20rem;
  line-height: 17rem;
  transform: translate(-30px, -50%);
  color: black;
  z-index: 1;
}
#page .banner .copy .bg span {
  position: relative;
  left: 10px;
}
#page .banner .copy h1,
#page .banner .copy .desc {
  position: relative;
  z-index: 2;
}
#page .banner .copy h1 {
  align-self: flex-start;
  margin-top: 0;
}
#page .banner .copy p {
  color: #b3b3b3;
  padding: 0;
}
#page .banner.full {
  display: block;
  padding: 0;
}
#page .banner.full .image {
  border-radius: 0;
}
#page .banner.full .image .logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
#page .banner.full .image .logos img {
  width: 50%;
  height: auto;
}
#page .banner.full .copy {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#page .banner.full .copy h1 {
  font-size: 2.4rem;
  margin: 0 0 20px;
}
#page .banner.full .copy .btn {
  flex: auto;
  margin: 0;
}
#page .banner.full .copy p {
  margin: 0 0 40px;
}
#page .full {
  position: relative;
  background: #1e1f1e;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
}
#page .full.top:after {
  top: -50px;
}
#page .full.bottom:after {
  bottom: -50px;
}
#page .full p {
  margin: 0 0 40px;
  font-size: 1.4rem;
  text-align: left;
  font-weight: 300;
  color: #b3b3b3;
}
#page .full p:last-child {
  margin: 0;
}
#page .full .btnWrap {
  margin-top: 40px;
}
#page .blocks {
  background: #b6b9b6;
  color: #111111;
  position: relative;
  z-index: 10;
}
#page .blocks article {
  box-sizing: border-box;
  padding: 40px;
}
#page .blocks article h3 {
  font-size: 1.4rem;
  line-height: 2.4rem;
}
#page .blocks article p {
  font-size: 1.2rem;
  margin-bottom: 0;
  color: #666;
}

.cta {
  width: 100%;
  min-height: 100vh;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #111;
}
.cta:after {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(17, 17, 17, 0.6);
}
.cta .copy {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cta .copy #logo {
  position: relative;
  top: 0;
  left: 0;
  width: 200px;
}
.cta .copy p {
  padding: 40px 0;
  font-weight: bold;
}

@media (min-width: 768px) {
  #page .pageHeader {
    padding: 0 60px;
    box-sizing: border-box;
  }
  #page .banner {
    flex-direction: row;
    min-height: 100vh;
  }
  #page .banner .copy {
    padding: 100px;
    border-radius: 0 0 20px 20px;
  }
  #page .banner .copy h1 {
    font-size: 3rem;
    line-height: 3.6rem;
    line-break: break-word;
  }
  #page .banner .copy p {
    font-size: 1.2rem;
  }
  #page .banner .copy .btn {
    margin-top: 40px;
  }
  #page .banner.full {
    padding: 0 40px;
  }
  #page .banner.full .copy h1 {
    flex: 0 0 50%;
    font-size: 5rem;
    line-height: normal;
    letter-spacing: 6px;
    position: relative;
    font-weight: lighter;
    z-index: 2;
  }
  #page .banner.full .copy h1 span {
    position: relative;
    left: 3px;
  }
  #page .banner.full .copy .desc {
    position: relative;
    z-index: 2;
    align-self: flex-start;
  }
  #page .banner.full .image {
    border-radius: 20px 20px 0 0;
  }
  #page .banner.full .image .logos img {
    width: 25%;
  }
  #page .full {
    padding: 100px;
  }
  #page .full p {
    font-size: 1.6rem;
  }
  #page .full .btnWrap {
    margin-top: 40px;
    align-self: flex-start;
  }
  #page .blocks {
    padding: 60px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  #page .blocks article {
    padding: 40px 100px;
  }
  #page .blocks article h3 {
    font-size: 1.6rem;
  }
  #page .cta {
    padding: 100px;
  }
  #page .cta p {
    max-width: 800px;
    font-size: 2rem;
    margin: 40px 0 60px;
  }
}
@media (min-width: 991px) {
  #page .blocks article {
    flex: 0 0 50%;
  }
}
@media (min-width: 1200px) {
  #page .full {
    flex-direction: row-reverse;
  }
  #page .full p {
    padding-left: 100px;
    max-width: 800px;
  }
  #page .full .btnWrap {
    flex: 0 0 300px;
    margin-top: 0;
    align-self: center;
  }
  #page .banner.full .copy {
    flex-direction: row;
  }
  #page .banner.full .copy h1 {
    margin: 0;
  }
  #page .banner.full .copy .desc {
    padding-left: 40px;
  }
}
#websites {
  box-sizing: border-box;
  background: #1e1f1e;
  max-height: 0;
  height: auto;
  overflow: hidden;
  position: relative;
  z-index: 999;
}
#websites.show {
  scroll-padding: 10px;
  max-height: 9999px;
  transition: all 0.8s ease-in-out;
}
#websites .wrap {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  border-radius: 20px;
  overflow: hidden;
}
#websites .wrap a {
  flex: 0 0 100%;
  display: block;
  overflow: hidden;
  position: relative;
}
#websites .wrap a img {
  width: 100%;
  margin: 0 0 -5px;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
#websites .wrap a .overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  background: none;
  border: 5px solid white;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate(0, 200px) scale(0.9);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
#websites .wrap a .overlay h3 {
  font-size: 1.4rem;
  margin: 0;
}
#websites .wrap a .overlay p {
  font-size: 0.8rem;
  margin: 0;
}
#websites .wrap a:hover {
  cursor: pointer;
}
#websites .wrap a:hover .overlay {
  transform: scale(1);
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
#websites .wrap a:hover img {
  transform: scale(1.5);
  transition: all 0.2s ease-in-out;
  filter: blur(5px);
}

@media (min-width: 768px) {
  #websites.show {
    padding: 40px;
  }
  #websites .wrap {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid black;
  }
  #websites .wrap a {
    flex: 0 0 50%;
  }
  #websites .wrap a .overlay {
    padding: 20px;
  }
  #websites .wrap a .overlay p.desc {
    font-size: 1.2rem;
  }
}
@media (min-width: 991px) {
  #websites .wrap {
    max-width: 1400px;
  }
  #websites .wrap a {
    flex: 0 0 33.333333%;
  }
  #websites .wrap a .overlay {
    padding: 10px;
  }
  #websites .wrap a .overlay h3 {
    font-size: 1.2rem;
  }
  #websites .wrap a .overlay p {
    font-size: 0.8rem;
  }
  #websites .wrap a .overlay p.desc {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  #websites .wrap a .overlay {
    padding: 30px;
  }
  #websites .wrap a .overlay h3 {
    font-size: 1.6rem;
  }
  #websites .wrap a .overlay p {
    font-size: 1rem;
  }
  #websites .wrap a .overlay p.desc {
    font-size: 1.2rem;
  }
}
.modal {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9999;
  margin: 0 auto;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 1.4rem;
  background: #1e1f1e;
  box-sizing: border-box;
  color: #cccccc;
  display: none;
  font-family: "menlo", "courier", serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.modal::-webkit-scrollbar {
  display: none;
}
.modal.show {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.modal .modalCopy {
  height: 100dvh;
  width: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
  padding-bottom: 60px;
}
.modal img {
  width: 100%;
  max-width: 400px;
  height: auto;
  margin: 0 0 20px;
}
.modal .close {
  color: #cccccc;
  background: none;
  border: none;
  box-shadow: 0 0 0;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: #1e1f1e;
  border-top: 1px solid #cccccc;
  width: 100%;
  text-align: left;
  letter-spacing: 0.5px;
}
.modal .close .block {
  display: inline-block;
  background: #cccccc;
  color: #1e1f1e;
  padding: 5px;
  border-radius: 5px;
  font-size: 0.6rem;
}
.modal .prompt {
  font-size: 1rem;
}
.modal .userInput {
  color: white;
}
.modal .userInput input,
.modal .userInput textarea {
  background: none;
  border: none;
  font-family: "menlo", "courier", serif;
  font-size: 1.2rem;
  line-height: 1.4rem;
  caret-color: transparent;
  color: green;
  margin-bottom: 10px;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 0;
  display: inline-block;
}
.modal .userInput input:focus,
.modal .userInput textarea:focus {
  outline: none;
}
.modal .userInput.focus:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 18px;
  background: white;
  animation: blink 0.9s infinite;
  animation-timing-function: ease;
}
.modal .userInput.focus.error:after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 18px;
  background: red;
}

#OffTheDock {
  background: white;
  width: 100%;
  color: #1e1f1e;
}
#OffTheDock .colorScheme {
  display: flex;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}
#OffTheDock .colorScheme div {
  width: 20%;
  display: block;
}
#OffTheDock .colorScheme div:nth-child(1) {
  background-color: #F1E4B2;
}
#OffTheDock .colorScheme div:nth-child(2) {
  background-color: #FF8D6B;
}
#OffTheDock .colorScheme div:nth-child(3) {
  background-color: #45C2B1;
}
#OffTheDock .colorScheme div:nth-child(4) {
  background-color: #A191B2;
}
#OffTheDock .colorScheme div:nth-child(5) {
  background-color: #002D74;
}
#OffTheDock .pageContainer {
  background: white;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
#OffTheDock .pageContainer .graphics {
  box-sizing: border-box;
  padding: 20px;
}
#OffTheDock .pageContainer svg {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
}
#OffTheDock .pageContainer .logo {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin-top: 100px;
}
#OffTheDock .pageContainer .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}
#OffTheDock .pageContainer .icon svg {
  max-width: 80%;
}
#OffTheDock .pageContainer .tsi {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
#OffTheDock .pageContainer .stickers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin: 100px 0;
}
#OffTheDock .pageContainer .stickers svg {
  width: 60%;
}
#OffTheDock .pageContainer .sticker3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#OffTheDock .pageContainer .businessCards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
}
#OffTheDock .pageContainer .thirds {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
}
#OffTheDock .pageContainer .thirds svg {
  width: 60%;
}
#OffTheDock .pageContainer .merch {
  margin-top: 100px;
}
#OffTheDock .pageContainer .merch div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#OffTheDock .pageContainer .merch img {
  max-width: 100%;
}

@media (min-width: 768px) {
  #OffTheDock .pageContainer .logo {
    height: 100dvh;
    margin: 0;
  }
  #OffTheDock .pageContainer .logo svg {
    width: 800px;
  }
  #OffTheDock .pageContainer .stickers #sticker1 {
    width: 40%;
  }
  #OffTheDock .pageContainer .merch div,
  #OffTheDock .pageContainer .stickers,
  #OffTheDock .pageContainer .thirds {
    flex-direction: row;
  }
  #OffTheDock .pageContainer .merch div img {
    max-width: 50%;
  }
}

