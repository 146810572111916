.modal {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 9999;
  margin: 0 auto;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 1.4rem;
  background: $dark;
  box-sizing: border-box;
  color: #cccccc;
  display: none;
  font-family: 'menlo', 'courier', serif;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  &.show {
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .modalCopy {
    height: 100dvh;
    width: 100%;
    overflow-y: scroll;
    box-sizing: border-box;
    padding-bottom: 60px;
  }
  img {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 0 0 20px;
  }
  .close {
    color: #cccccc;
    background: none;
    border: none;
    box-shadow: 0 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 10px;
    background: $dark;
    border-top: 1px solid #cccccc;
    width: 100%;
    text-align: left;
    letter-spacing: 0.5px;
    .block {
      display: inline-block;
      background: #cccccc;
      color: $dark;
      padding: 5px;
      border-radius: 5px;
      font-size: 0.6rem;
    }
  }
  .prompt {
    font-size: 1rem;
  }
  .userInput {
    color: white;
    input,
    textarea {
      background: none;
      border: none;
      font-family: 'menlo', 'courier', serif;
      font-size: 1.2rem;
      line-height: 1.4rem;
      caret-color: transparent;
      color: green;
      margin-bottom: 10px;
      height: 20px;
      margin: 0;
      padding: 0;
      width: 0;
      display: inline-block;
      &:focus {
        outline: none;
      }
    }
    &.focus {
      &:after {
        content: '';
        display: inline-block;
        width: 10px;
        height: 18px;
        background: white;
        animation: blink 0.9s infinite;
        animation-timing-function: ease;
      }
      &.error {
        &:after {
          content: '';
          display: inline-block;
          width: 10px;
          height: 18px;
          background: red;
        }
      }
    }
  }

}

@media (min-width: 768px) {

}