#home {
  position: relative;
  background: $dark;
  #websites {
    background: none;
  }
  .clientList {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0;
    a {
      display: block;
      max-width: 80px;
      &:hover {
        svg {
          path {
            fill: white;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
    svg {
      width: 100%;
      path {
        fill: darken(white, 60%);
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .textSection {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 3;
    padding: 20px;
    box-sizing: border-box;
    color: white;
    .copy {
      max-width: 100%;
      box-sizing: border-box;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
      #logo {
        width: 110px;
        margin-bottom: 40px;
      }
      h1 {
        font-size: 3rem;
        margin: 0;
        align-self: flex-start;
        color: white;
      }
      h2 {
        font-size: 2.4rem;
        margin: 0;
      }
      p {
        color: darken(white, 60%);
        font-size: 1.6rem;
        line-height: 2.2rem;
        margin: 0;
        padding: 0 0 40px;
        font-weight: 300;
        a {
          text-decoration: none;
          font-family: 'helvetica', sans-serif;
          color: darken(white, 30%);
          transition: all 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            color: white;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .btnGroup {
        display: flex;
        align-items: center;
        gap: 25px;
        margin: 0 0 40px;
        a {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: #666666;
          text-decoration: none;
          span {
            font-size: 0.5rem;
            letter-spacing: 1px;
            display: block;
            text-align: center;
            text-transform: uppercase;
            color: white;
            transition: all 0.2s ease-in-out;
          }
          svg {
            width: 100%;
            height: 25px;
            transform: translateY(0);
            transition: all 0.2s ease-in-out;
            path,
            circle {
              fill: white;
            }
          }
          &:hover {
            cursor: pointer;
            svg {
              transform: translateY(-5px);
              transition: all 0.2s ease-in-out;
            }
          }
        }
      }
      .btn {
        align-self: flex-start;
      }
    }
  }
  // .scroll {
  //   position: absolute;
  //   bottom: 40px;
  //   left: 50%;
  //   transform: translate(-50%,0);
  //   z-index: 99;
  //   display: none;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   color: darken(white, 60%);
  //   font-size: 0.8rem;
  //   .arrow {
  //     animation: bounce 1s infinite alternate;
  //     &:before {
  //       content: "";
  //       display: block;
  //       width: 8px;
  //       height: 1px;
  //       background: grey;
  //       bottom: -5px;
  //       left: 50%;
  //       position: fixed;
  //       transform: translateX(-3px) rotate(-45deg);
  //       transform-origin: right;
  //     }
  //     &:after {
  //       content: "";
  //       display: block;
  //       width: 8px;
  //       height: 1px;
  //       background: grey;
  //       bottom: -5px;
  //       left: 50%;
  //       position: fixed;
  //       transform: translateY(5px) rotate(-135deg);
  //       transform-origin: left;
  //     }
  //   }
  //   &:hover {
  //     cursor: pointer;
  //   }
  // }
}

@media (min-width: 768px) {
  #home {
    .textSection {
      padding: 80px 40px;
      // .scroll {
      //   display: flex;
      // }
      .btnGroup {
        gap: 40px;
      }
      .copy {
        h1,
        h2 {
          margin: 0;
          font-size: 5rem;
          white-space: nowrap;
        }
        h2 {
          font-size: 3.6rem;
        }
        p {
          font-size: 2rem;
          line-height: normal;
        }
      }
    }
  }
}

@media (min-width: 991px) {
  #home {
    .textSection {
      flex-direction: row;
      flex-wrap: nowrap;
      .copy {
        margin: 0 100px;
        p {
          font-size: 2.4rem;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  #home {
    .textSection {
      .copy {
        max-width: 1080px;
        margin: 0 auto;
      }
    }
  }
}