@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Poppins:wght@100;300;400&display=swap');

$dark: #1e1f1e;

@keyframes bounce {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(5px);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background: $dark;
  scroll-behavior: smooth;
}
.hideMobile {
  display: none !important;
}
.showMobile {
  display: initial !important;
}
@property --angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0deg;
}
@keyframes rotate { 
  to { --angle: 360deg; }
}
.btnWrap {
  display: block;
  border-radius: 12px;
  box-sizing: border-box;
  border: 2px solid transparent;
  width: 100%;
  cursor: pointer;
  --bg: grey;
  --gradient: conic-gradient(
    from var(--angle),
    #70D6FF, #FF70A6, #FF9770, #FFD670, #E9FF70, #70D6FF
  );
  background-image: var(--gradient);
  background: 
  linear-gradient(var(--bg),
  var(--bg)) padding-box,
  var(--gradient) border-box;
  animation: rotate 2s infinite linear running;
  &:is(:hover, :focus-within) {
    animation-play-state: paused;
  }
}
.btn {
  background: lighten(black, 5%);
  text-align: center;
  width: 100%;
  line-height: 60px;
  margin: 0 auto;
  color: white;
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 10px;
  border: none;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  text-shadow: none;
  font-size: 1.2rem;
  &:hover {
    cursor: pointer;
    background: lighten(black, 10%);
    letter-spacing: 2.1px;
    transition: all 0.2s ease-in-out;
  }
  &:active {
    background: lighten(black, 8%);
    outline: none;
    box-shadow: 0 0 5px 5px black inset;
    transition: none;
  }
  &:focus {
    outline: none;
  }
}
p {
  font-size: 1.4rem;
  font-weight: 300;
}
h3,
.keyTitle {
  margin: 0;
  font-size: 2rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  .keyWrap {
    width: 85px;
    height: 80px;
    background: linear-gradient(to left top, lighten(grey, 10%), lighten(grey, 25%));
    border-radius: 6px;
    padding: 2px;
  }
  .key {
    display: none;
    width: 85px;
    height: 80px;
    font-size: 2rem;
    border-radius: 6px;
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    background: lightgrey;
    font-weight: bold;
  }
  .word {
    position: relative;
    top: 40px;
    left: 0;
    text-indent: 90px;
    display: block;
    flex: 0 0 100%;
    transform: translate(-88px, 0);
    padding-bottom: 20px;
    span {
      text-indent: 0;
    }
  }
  &.dark {
    span {
      background: $dark;
      border-color: white;
      color: white;
    }
  }
}
#logo {
  position: relative;
  z-index: 99;
  width: 150px;
  fill: white;
  box-sizing: border-box;
  #fill {
    fill: $dark;
  }
}
#video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
.container {
  position: relative;
  transform: scale(1);
  transition: all 0.5s ease-in-out;
  &.out {
    transform: scale(0.98);
    transition: all 0.5s ease-in-out;
  }
}

@media (min-width: 768px) {
  .showMobile {
    display: none !important;
  }
  .hideMobile {
    display: inline-block !important;
  }
  .scroll {
    bottom: 80px;
  }
  .btnWrap,
  .btn {
    max-width: 300px;
  }
  #home {
    #intro {
      blockquote {
        font-size: 1.6rem;
      }
    }
  }
}